import React, { useEffect, useContext, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Typography } from "../../components/mui";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import {
  useSelectPaymentMethodContext,
  useSelectPaymentMethodDispatch,
  useTransactionDispatch,
  selectPaymentMethodTypes,
  transactionTypes
} from "../../context";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { ListElement } from "../../services/sdk/components/ListElement";
import { SwivelContext } from "../../services/sdk/context/SwivelProvider";
import { useSwivel } from "../../services/sdk/hooks/useSwivel";
import { addBreadCrumb } from "../../store/navhistory/navhistory.slice";
import {
  clearSavedAccount,
  postSaveAccount,
  setExpressEnhancedAccount,
  setFulfilledAccounts,
  clearExpressEnhancedAccount,
  patchSaveAccount
} from "../../store/savedAccounts/savedAccounts.slice";
import { EXPRESS_PAY, REGISTERED } from "../../utils/constants/constants";
import { routeMap } from "../../utils/data/routeMap";
import { useMaterialTheme } from "../../utils/hooks/useMaterialTheme";
import { ButtonWrapper, SuccessWrapper, ContentWrapper, Container, TargetElement } from "./styles";

const ConnectBank = () => {
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const savedAccounts = (state) => state.savedAccounts;

  const { config, details } = useSelector(institutionState);
  const { authToken, user, flow } = useSelector(authState);
  const { expressEnhancedAccount, getAccounts } = useSelector(savedAccounts);

  const { previousRoute } = useContext(SwivelContext);
  const [route] = useState(routeMap?.get(previousRoute) || routeMap?.get("/accounts"));
  const { entryPoint } = useSelectPaymentMethodContext();
  const selectPaymentDispatch = useSelectPaymentMethodDispatch();
  const transactionDispatch = useTransactionDispatch();

  const [fulfilled, setFulfilled] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [hasDuplicates, setHasDuplicates] = useState([]);

  const dispatch = useDispatch();
  const materialTheme = useMaterialTheme();
  const { trackEvent, actions, categories } = useGoogleAnalytics();
  const { isNextGenPayments } = useFlags();

  const isApprovedUser = user?.role === "approved-user";
  const userName = isApprovedUser ? `${user?.first_name} ${user?.last_name}` : user?.account_name;
  // Setup for enhanced SDK
  const isExperiencedEnabled = details?.services?.sdk?.enhanced;
  const achWebAccountID = `${details?.ach_web_verification_id}`;

  const isCardEnabled = details?.services?.payments?.card?.enabled;
  const isApplePayEnabled = isCardEnabled && details?.services?.digital_wallets?.apple_pay?.enabled;

  const element = "enhanced-ach-experience";
  const experienceType = {
    "registered": "VERIFY_ACH_ENHANCED_MULTIPLE_ACCOUNTS",
    "express-pay": "VERIFY_ACH_ENHANCED_SINGLE_ACCOUNT"
  };

  const options = {
    blacklistedAba: details?.native_aba_list
  };

  const token = authToken?.value;
  const initialization = {
    ACHWebVerificationAccountId: achWebAccountID,
    IntegrationID: process.env.REACT_APP_ACH_INTEGRATION_PARTNER,
    Element: element,
    Experience: experienceType["express-pay"], // HACK: per product, set flow to single experience
    Mode: "selection",
    Appearance: {
      // first values below are future fields, second values are current fields
      PrimaryColor: details?.primary_color ?? details?.primary_button_background_color,
      PrimaryFontColor: details?.primary_font_color ?? details?.primary_button_font_color,
      SecondaryColor: details?.secondary_color ?? details?.secondary_button_background_color,
      SecondaryFontColor: details?.secondary_font_color ?? details?.secondary_button_font_color,
      TertiaryColor: details?.tertiary_color ?? details?.tertiary_button_background_color,
      TertiaryFontColor: details?.tertiary_font_color ?? details?.tertiary_button_font_color
    },
    BrandInfo: {
      InstitutionName: details?.brand_name,
      SupportPhoneNumber: details?.brand_phone,
      SupportEmail: details?.brand_email
    }
  };

  const payload = {
    ACHWebVerificationAccountId: achWebAccountID,
    experience: experienceType["express-pay"], // HACK: per product, set flow to single experience
    userFlow: flow,
    user: {
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.email_address
    }
  };

  const formatAchAccount = (account) => {
    return {
      ...account,
      type: "ach",
      label: account?.banking_details?.name_of_bank || "Account",
      last_4: account.last4,
      banking_details: {
        ...account?.banking_details,
        account_type: account?.banking_details?.type
      }
    };
  };

  const { initialize, closeFlow, isLoading, alert, verifyReponse, verifyError } = useSwivel({ token, initialization, payload, options });

  useEffect(() => {
    if (isExperiencedEnabled) {
      initialize();
      return () => closeFlow();
    }
  }, [isExperiencedEnabled]);

  useEffect(() => {
    if (verifyReponse) {
      const saveAccountPayload = verifyReponse?.valid?.map((ele) => ({
        banking_details: {
          account_number: ele.accountNumber,
          business: false,
          name_of_bank: ele.nameOfInstitution,
          name_on_account: ele.accountOwner.length ? ele.accountOwner : userName,
          routing_number: ele.routingNumber,
          type: ele.accountType
        },
        last4: ele.accountNumberLast4,
        primary: false,
        type: "banking",
        validation_type: ele.validationType,
        vendor: ele.vendor,
        validated: true,
        token
      }));

      if (flow === REGISTERED) { // Registered Flow
        const mappedAccounts = saveAccountPayload?.map((account) => {
          const isDuplicate = getAccounts?.response?.results.find((ele) => {
            const last4 = ele?.last_4 === saveAccountPayload[0]?.last4;
            const routingNumber = ele?.banking_details?.routing_number === saveAccountPayload[0]?.banking_details?.routing_number;
            const type = ele?.banking_details?.type === saveAccountPayload[0]?.banking_details?.type;
            return last4 && routingNumber && type;
          });

          if (isDuplicate) {
            // Special check for chase Finicity connections to handle their tokenization process
            // This specific routing number is what has been returning our r04s
            if (isDuplicate?.banking_details?.routing_number === "028000121") {
              const chaseAccount = {
                token,
                id: isDuplicate?.id,
                banking_details: {
                  account_number: account?.banking_details?.account_number
                }
              };
              dispatch(patchSaveAccount(chaseAccount))
                .unwrap()
                .then((response) => response)
                .catch(() => { });
            }
            setHasDuplicates([...hasDuplicates, isDuplicate]);
          } else {
            if (isNextGenPayments || isApplePayEnabled) {
              const achPayload = formatAchAccount(account);
              transactionDispatch({
                type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
                value: achPayload
              });
            }
            const response = dispatch(postSaveAccount(account))
              .unwrap()
              .then((response) => response);

            return response;
          }
        });
        Promise
          .allSettled(mappedAccounts)
          .then((response) => {
            const fulfilled = [];
            const rejected = [];

            response?.forEach((account) => {
              if (account.status === "fulfilled") { fulfilled.push(account); }
              if (account.status === "rejected") { rejected.push(account); }
            });

            setFulfilled(fulfilled);
            setRejected(rejected);
            dispatch(setFulfilledAccounts(fulfilled));
          });
      } else { // Guest Flow
        const [payload] = saveAccountPayload;
        dispatch(setExpressEnhancedAccount(payload));
        const achPayload = formatAchAccount(payload);
        if (isApplePayEnabled) {
          delete achPayload.last4;
        }
        transactionDispatch({
          type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
          value: achPayload
        });
        selectPaymentDispatch({
          type: selectPaymentMethodTypes.UPDATE_AVAILABLE_ACH,
          value: [achPayload]
        });
        const makePaymentRoute = routeMap.get("/make-payment");
        selectPaymentDispatch({
          type: selectPaymentMethodTypes.SAVE_ENTRY_POINT,
          value: makePaymentRoute
        });
      }
    }

    return () => {
      dispatch(clearSavedAccount());
      dispatch(clearExpressEnhancedAccount());
      setFulfilled([]);
      setRejected([]);
    };
  }, [flow, verifyReponse]);

  // updating breadCrumb when connect-bank is successful
  // as part of the express pay flow
  useEffect(() => {
    if (flow === EXPRESS_PAY && verifyReponse?.valid) {
      const routeItem = routeMap.get("/make-payment");
      const navObj = {
        title: routeItem.title,
        route: routeItem.route,
        time: Date.now()
      };
      dispatch(addBreadCrumb(navObj));
    }
  }, [flow, verifyReponse]);


  const blacklistedAba = verifyReponse?.blacklisted;
  const invalidAccountType = verifyReponse?.invalidAccountType;

  const hasValidAccounts = verifyReponse?.valid?.length;
  const hasInvalidTypes = invalidAccountType?.length;
  const hasBlacklistedAba = blacklistedAba?.length;
  const hasBlacklistOrInvalid = !!(hasBlacklistedAba || hasInvalidTypes);

  useEffect(() => {
    if (hasBlacklistOrInvalid) {
      trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_SUCCESS);
    } else {
      trackEvent(actions.CONNECT_ACH_ACCOUNT, categories.ATTEMPT_FAILURE);
    }
  }, [hasBlacklistOrInvalid]);

  const isSuccess = verifyReponse && fulfilled.length;
  const isFailure = verifyReponse && rejected.length;
  const hasApiResponse = !!(isSuccess || isFailure);

  const isInvalid = hasApiResponse && hasBlacklistOrInvalid;
  const isInvalidOnly = !hasValidAccounts && hasBlacklistOrInvalid;

  const isReadyToDisplay = !!(
    hasApiResponse
    || isInvalidOnly
    || expressEnhancedAccount
    || verifyError
    || alert?.message
  );

  const isAccountSaving = flow === REGISTERED && verifyReponse && !isReadyToDisplay;

  // eslint-disable-next-line no-confusing-arrow
  const formatMessage = (list, amount, multiple, single) => list > amount ? multiple : single;
  const accountsSuccess = formatMessage(isSuccess, 1, "accounts", "account");
  const accountsFailure = formatMessage(isFailure, 1, "accounts", "account");
  const invalidTypes = formatMessage(hasInvalidTypes, 1, "types are", "type is");
  const brandPhone = config?.brand_phone ? `at ${config?.brand_phone}` : "";

  const errorMessages = {
    blacklist: details?.native_aba_list_error_text || "Routing Number from this Financial Institution is not allowed.",
    accountType: `The following account ${invalidTypes} not accepted, please use a checking or savings account.`,
    default: "Bank account not verified."
  };

  return (
    <ContentWrapper>
      <Title title="Connect your bank" />

      <Alert {...alert} />
      <Alert severity="error" message={isFailure ? errorMessages.default : null} />
      <Alert severity="error" message={hasDuplicates.length > 0 ? "This account already exists in profile." : null} />
      <Alert severity="warning" sx={{ display: isInvalid || isInvalidOnly ? "inline-flex" : "none" }}>
        {hasBlacklistedAba ? <ListElement list={blacklistedAba} error={errorMessages.blacklist} /> : null}
        {hasInvalidTypes ? <ListElement list={invalidAccountType} error={errorMessages.accountType} /> : null}
      </Alert>
      <Alert severity="success" message={(isSuccess || expressEnhancedAccount) && hasDuplicates.length === 0 ? "Success!" : null} />

      <Container height={isLoading || !isReadyToDisplay ? "763px" : null}>
        <TargetElement id={element} tabIndex="-1" height={isLoading || !isReadyToDisplay ? "763px" : "100%"} />
        {
          isLoading || isAccountSaving
            ? <StyledCircularProgress size="3.75rem" sx={{ position: "relative", color: "#616f7d" }} />
            : null
        }
        {isSuccess || expressEnhancedAccount ? (
          <SuccessWrapper>
            {hasDuplicates?.length > 0 ? (
              // Duplicate Scenario: single account found a duplicate
              // TODO Scenario: multiple accounts added with a single duplicate AND all duplicates
              <>
                <Typography component="h2" variant="h3">Bank account not added.</Typography>
                <Typography>
                  Please try again if you would like to connect another bank to make a payment or
                  contact your Financial Institution {brandPhone} for assistance.
                </Typography>
              </>
            ) : (
              // Success Scenario
              <>
                <Typography component="h2" variant="h3">Thank you for adding your bank {accountsSuccess}!</Typography>
                {route.title !== "Profile" && fulfilled.length === 1 ? (
                  <>
                    <Typography>This account will be selected for the current payment.{" "}</Typography>
                    <Typography>If you want to use a different account, please select from the drop-down list on the payment page.{" "}</Typography>
                  </>
                ) : <Typography>You may now use your bank {accountsSuccess} to make a payment.</Typography>}
              </>
            )}
          </SuccessWrapper>
        ) : null}
        {alert?.type === "USER_CANCELLED" ?
          <SuccessWrapper>
            <Typography component="h2" variant="h3">Bank account not verified</Typography>
            <Typography>
              Please try again if you would like to connect your bank to make a payment or
              contact your Financial Institution {brandPhone} for assistance.
            </Typography>
          </SuccessWrapper>
          : null}
        {(!isSuccess && isFailure) || isInvalidOnly || verifyError ? (
          <SuccessWrapper>
            <Typography component="h2" variant="h3">Sorry, we are not able to connect your bank {accountsFailure}</Typography>
            <Typography>Please try again or contact your Financial Institution{" "}{brandPhone} for assistance.</Typography>
          </SuccessWrapper>
        ) : null}
        {isReadyToDisplay ? (
          <ButtonWrapper materialTheme={materialTheme}>
            <Button component={Link} to={entryPoint.route ? entryPoint.route : route.route}
              containerStyle="grid-area: button;">
              Back to{" "}
              {entryPoint.route ? entryPoint.title : route.title}
            </Button>
          </ButtonWrapper>
        ) : null}
      </Container>
    </ContentWrapper>
  );
};

export default ConnectBank;
